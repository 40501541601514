%button {
  outline: none;
  cursor: pointer;
  border: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: none;
}

%boxShadow {
  box-shadow: 0 2px 8px rgba(0, 0, 0, .1);
}
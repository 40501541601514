@import '../../sass/variables.scss';

.cart { 
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center; 
  margin: 1rem;
  border-radius: 2rem;
  cursor: pointer;
  color: $color-white;
  font-size: 2.5rem;
  transition: all .3s;
  text-decoration: none;
  
  &:hover {
    color: $color-black;
  }
}

.text {
  font-weight: 600;
  margin-left: 1rem;
}

.touched {
  color: $color-black;
}
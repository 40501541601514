@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.menu {
  color: $color-white;
  font-size: 2.3rem;
  padding: .5rem;
  cursor: pointer;

  @include respond($mid) {
    display: none;
  }
}
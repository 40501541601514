@import '../../sass/variables.scss';

.products {
  width: 100%;
  background-color: $color-grey;
}

.bannerImg {
  width: 100%;
  border-radius: 1rem;
}
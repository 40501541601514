@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.home {
  width: 100%;
  background-color: $color-grey;
}

.header {
  @include respond($big) {
    padding: 2rem;
    display: flex;
  }
}

.nav {
  display: none;

  @include respond($big) {
    display: block;
    background-color: $color-white;
    border-radius: 1rem;
    margin-right: 2rem;
    flex: 0 25rem;
  }
}

.slider {
  @include respond($big) { 
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    flex: 1;
    background-color: $color-white;
  }
}

.products {
  padding: 1rem 0;
}
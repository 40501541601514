@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.products {
  width: 100%;
  padding-bottom: 3rem;
}

.latest, .recent {
  padding: 1rem;
  padding-left: 2rem;
  background-color: $brand-color-sub;
  color: $color-black;
  font-weight: 600;
}

.recent {
  color: $color-white;
}

.display, .suggests {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, auto));
  gap: .7rem;
  padding: 1rem;
  justify-content: center;

  @include respond($mid) {
    grid-template-columns: repeat(auto-fill, minmax(20rem, auto));
    padding: 2rem 1rem;
  }
}

.advert {
  background-color: $color-white;
  margin: 1rem 0 2rem 0;
}
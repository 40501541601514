@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.header {
  background-color: $brand-color-main;
  padding: .5rem;

  @include respond($mid) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
  }
}

.section1 {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  padding: .5rem;

  @include respond($mid) {
    flex: 1;
  }
}

.section2 {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @include respond($mid) {
    flex: 2;
  }
}
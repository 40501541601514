@import "../../sass/variables.scss";

.notification {
  width: 100%;
  background-color: $brand-color-sub;
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  animation: slidein .3s;
}

@keyframes slidein {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
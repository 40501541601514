@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.product {
  padding: 1rem;
  border-radius: .7rem;
  background-color: $color-white;
  overflow: hidden;
  text-align: center;
  transition: all .2s;
  text-decoration: none;
  color: $color-black;

  @include respond($mid) {
    flex: 0 20rem;
    margin: 1rem;
  }

  &:hover {
    box-shadow: 1px 2px 10px rgba(0, 0, 0, .2);
    transform: scale(1.02);
  }
}

.imageContainer {
  width: auto;
  height: 20rem;
  overflow: hidden;
  margin-bottom: 1rem;
  background: url('../../assets/img/product-placeholder.png') no-repeat center/cover;
}

.image {
  width: 100%;
  border-radius: 1rem;
}

.name {
font-size: 1.5rem;
}

.price {
  font-weight: 600;
  padding-top: 1rem;
}
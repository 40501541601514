@import '../../sass/variables.scss';
@import '../../sass/placeholders.scss';
@import '../../sass/mixins.scss';

.searchContainer {
  width: 100%;
  padding: 1rem;
}

.searchBarContent {
  width: 100%;
  position: relative;
}

.searchBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.input {
  background-color: $color-white;
  width: 100%;
  padding: 1rem;
  padding-right: 0;
  border-radius: 3rem 0 0 3rem;
  border: none;
  outline: none;
  border: 3px solid $brand-color-sub;

  &:focus {
    border: 3px solid $color-white;
  }

  @include respond($mid) {
    flex: 1;
  }
}

.action {
  background-color: $brand-color-sub;
  padding: 1rem;
  padding-left: .5rem;
  font-size: 2rem;
  border: none;
  outline: none;
  border-radius: 0 3rem 3rem 0;
  transition: all .3s ease;
  cursor: pointer;

  &:hover {
    background-color: $brand-color-sub-light;
  }
}

.dropdown {
  background-color: $color-white;
  padding: 1rem;
  position: absolute;
  top: 4.5rem;
  left: 0;
  width: 100%;
  padding-bottom: 5rem;
  @extend %boxShadow;
  z-index: 50;
}
// colors
$brand-color-main: #f00f0f;
$brand-color-main-light:#f02a2a;
$brand-color-sub: #ffcc00;
$brand-color-sub-light: #ffff00e3;
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #f1f1f1;
$color-grey-light: #F8F8F8;
$color-grey-dark: #a09191;
$color-grey-dark-2: rgba(250, 250, 250, .04);

// For media queries
$mid: 750px;
$big: 1000px;
$max: 1200px;



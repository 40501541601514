@import '../../sass/variables.scss';

.loader {
  width: 100%;
  padding: 2rem;
  padding-top: 3rem;
}

.loading {
  margin: auto;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background-color: $color-grey-light;
  border: 1rem solid $brand-color-sub;
  border-top-color: $brand-color-main;
  animation: spin 1s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@import '../../sass/variables.scss';
@import '../../sass/placeholders.scss';
@import '../../sass/mixins.scss';


.content {
  width: 100%;
  padding: 2rem;
}

.header {
  color: $brand-color-main;
  font-size: 2.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid $color-grey;
}

.heading {
  color: $brand-color-main;
  margin-bottom: 1rem;
}

.notice {
  padding: 1rem;
}

.order {
  margin-top: 2rem;

  @include respond($mid) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.customer {
  padding: 1rem;
  margin-bottom: 2rem;
}

.items {
  padding: 1rem;
  margin-bottom: 2rem;
}

.edit {
  display: block;
  margin-top: 1rem;
  width: 100%;
  padding: .5rem;
  font-size: 1.5rem;
  text-align: center;
  color: $color-black;
  border-radius: 1rem;
  text-align: center;
  // border: 1px solid $color-black;
  transition: all .3s ease;

  &:hover {
    transform: translateY(-2px);
  }
}

.thanks {
  padding: 1rem;

  & p {
    margin-bottom: 1rem;
  }

  & a {
    color: $brand-color-main;

    &:hover {
      color: $brand-color-sub;
      font-weight: 600;
    }
  }
}

.finalOrder {
  padding: 1rem;
  margin-bottom: 2rem;

  & p {
    padding: .5rem;
  }
}

.pad {
  margin-bottom: 4rem;
}
@import '../../sass/variables.scss';
@import '../../sass/placeholders.scss';
@import '../../sass/mixins.scss';

.container {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  width: 100%;
  padding: 1rem;

  @include respond($mid) {
   padding: 7rem;
   padding-top: 2rem;
  }

  & > * {
    flex: 1 100%;
  }
}

.header {
  color: $brand-color-main;
  font-size: 2.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid $color-grey;
}

.notice {
  padding: 1rem;
}

.items {
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid $color-grey;
}

.action {
  padding: 3rem 1rem;
}

.grandTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: $brand-color-sub;
  @extend %boxShadow;
  margin-bottom: 2rem;
  padding: .5rem;
}

.clear {
  @extend %button;
  background-color: transparent;
  text-decoration: underline;
  color: $color-black;
  font-size: 1.5rem;
  margin: .5rem;
  justify-self: stretch;
  transition: all .3s ease;

  &:hover {
    transform: translateY(-2px);
  }
}

.totalPrice {
  padding: 1rem;
  color: $brand-color-main;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

@import './sass/variables.scss';

html {
  font-size: 10px;
}

*, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1.7rem;
  width: 100%;
}

// modify alice carousel dots
ul.alice-carousel__dots {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  padding: 1rem;
  margin: 0;
}
@import '../../sass/variables.scss';

.nav {
  width: 100%;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;
  height: 100vh;
  background-color: rgba(0 , 0, 0, .5);
  cursor: pointer;
}

.content {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: $color-white;
  width: 80%;
  max-width: 30rem;
  height: 100%;
  overflow: auto;
  padding-bottom: 3rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $brand-color-main;
  border-bottom: 1rem solid rgba(250, 250, 250, .4);
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
}

.logo {
  flex: 0 10rem;
}

.img {
  width: 100%;
}

.button {
  font-size: 3rem;
  color: $color-white;
  cursor: pointer;

  &:hover {
    color: $color-black;
  }
}
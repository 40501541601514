@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';
@import '../../sass/placeholders.scss';

.contentWrapper {
  width: 100%;
}

.content {
  width: 100%;
  padding: 1rem;
  flex: 1;

  @include respond($mid) {
    display: flex;
    justify-content: center;
  }
}

.image {
  border-radius: 1rem;
  padding: 1rem;
  flex: 0 40rem;
  max-height: 40rem;
  margin-bottom: 1rem;

  & img {
    width: 100%;
    max-height: 40rem;
    object-fit: contain;
  }
}

.description {
  padding: .5rem;
  padding-bottom: 1rem;
  flex: 1;
}

.title {
  font-size: 2rem;
  font-weight: 400;
  border-bottom: 2px solid $color-grey;
  padding: 1rem;
}

.detailsBelow {
  padding: 1rem;
  color: $brand-color-main;
}

.price {
  font-size: 2rem;
  font-weight: 600;
  padding: 1rem;
}

.action {
  display: flex;
  flex-direction: row wrap;
  justify-content: center;
  padding: 1rem;
  padding-bottom: 3rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid $color-grey;
}

.inputContainer {
  flex: 0 7rem;
}

.input {
  @extend %button;
  padding: .8rem .5rem;
  width: 7rem;
  border-radius: 1rem;
  border: 2px solid $brand-color-sub;
  transition: all .3s ease;

  &:hover {
    border-color: $color-black;
    }
}

.button {
  @extend %button;
  @extend %boxShadow;
  padding: 1rem;
  flex: 1;
  margin-left: 1rem;
  background-color: $brand-color-sub;
  border-radius: 1rem;
  transition: all .3s ease;

  &:hover {
    transform: translateY(-2px);
  }
}

.infoContainer {
  padding: 2rem 2rem 15rem;
  border-top: 2px solid $color-grey;
  margin-top: 2rem;
}

.infoTitle {
  font-weight: 600;
  padding: 1rem;
}

.infoBody {
  padding: 1rem;
}




@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.nav {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 1rem 2rem;
  list-style: none;

  & a {
    text-decoration: none;
    color: inherit;
  }
}

.item {
  padding: 1.3rem 0;

  @include respond($big) {
    padding: .8rem 0;
  }

  &:hover {
    color: $brand-color-main;
  }
}

.icon {
  padding-right: .9rem;
}
@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.topnav {
  display: flex;
  flex-flow: row;
  justify-content: center;
  background-color: $color-black;
  color: $color-white;
  padding: 1rem;
  
  & a {
    text-decoration: none;
    color: $color-white;
  }

  @include respond($mid) {
    font-size: 1.7rem;
  }
}

.item {
  padding: .5rem 1rem;
  cursor: pointer;

  &:nth-child(1) {
    display: none;

    @include respond($mid) {
      display: block;
    }
  }

  &:hover {
    color: $brand-color-sub;
  }
}

.icon {
  padding-right: .6rem;
}
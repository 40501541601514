@import '../../sass/variables.scss';
@import '../../sass/placeholders.scss';

.content {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 1rem;
  padding-bottom: 6rem;

  & h1 {
    color: $brand-color-main;
    font-size: 2.5rem;
    padding: 1rem;
    border-bottom: 2px solid $color-grey;
  }

  & p {
    padding: 1rem;
    padding-bottom: 3rem;
  }

  & a {
    color: $brand-color-main;
  }

}

.section {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
}
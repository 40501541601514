@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';

.brand {
  flex: 0 10rem;
  padding: .5rem;

  @include respond($mid) {
    flex: 0 15rem;
  }
}

.img {
  width: 100%;
}